export type GameAction = {
    target: string,
    text: string
}

export type GameNode = {
    id: string,
    audio: string,
    actions: GameAction[]
}


const CHAPTER_1 = [
    {
        id: '1',
        audio: '/audio/1.mp3',
        actions: [
            {
                target: '1.1',
                text: 'Zaczynamy'
            }
        ]
    },
    {
        id: '1.1',
        audio: '/audio/1.1.mp3',
        actions: [
            {
                target: '1.3',
                text: 'Manię'
            },
            {
                target: '1.2',
                text: 'Stasia'
            }
        ]
    },
    {
        id: '1.2',
        audio: '/audio/1.2.mp3',
        actions: [
            {
                target: '1.7',
                text: 'Wchodzisz na drzewo'
            },
            {
                target: '1.6',
                text: 'Pomagasz'
            }
        ]
    },
    {
        id: '1.3',
        audio: '/audio/1.3.mp3',
        actions: [
            {
                target: '1.4',
                text: 'Restauracja'
            },
            {
                target: '1.5',
                text: 'Pomoc mamie'
            }
        ]
    },
    {
        id: '1.4',
        audio: '/audio/1.4.mp3',
        actions: [
            {
                target: '1.14',
                text: 'Idziesz po wodę'
            }
        ]
    },
    {
        id: '1.5',
        audio: '/audio/1.5.mp3',
        actions: [
            {
                target: '1.21',
                text: 'Pracujesz dalej'
            }
        ]
    },
    {
        id: '1.6',
        audio: '/audio/1.6.mp3',
        actions: [
            {
                target: '1.9',
                text: 'Klikasz włącznik'
            }
        ]
    },
    {
        id: '1.7',
        audio: '/audio/1.7.mp3',
        actions: [
            {
                target: '1.8',
                text: 'Wybudzasz się'
            }
        ]
    },
    {
        id: '1.8',
        audio: '/audio/1.8.mp3',
        actions: [
            {
                target: '1.12',
                text: "Idziesz w krzaki"
            },
            {
                target: '1.13',
                text: 'Szukasz Mani'
            }
        ]
    },
    {
        id: '1.9',
        audio: '/audio/1.9.mp3',
        actions: [
            {
                target: '1.11',
                text: 'Szukasz samemu'
            },
            {
                target: '1.10',
                text: 'Idziesz do mamy'
            }
        ]
    },
    {
        id: '1.10',
        audio: '/audio/1.10.mp3',
        actions: [
            {
                target: '1.12',
                text: 'Idziesz w krzaki'
            }
        ]
    },
    {
        id: '1.11',
        audio: '/audio/1.11.mp3',
        actions: [
            {
                target: '1.12',
                text: 'Idziesz w krzaki'
            }
        ]
    },
    {
        id: '1.12',
        audio: '/audio/1.12.mp3',
        actions: [
            {
                target: '1.13',
                text: 'Szukasz Mani'
            }
        ]
    },
    {
        id: '1.13',
        audio: '/audio/1.13.mp3',
        actions: [
            {
                target: '1.19-mania',
                text: 'Idziesz na skrzyżowanie'
            }
        ]
    },
    {
        id: '1.14',
        audio: '/audio/1.14.mp3',
        actions: [
            {
                target: '1.15',
                text: 'Prosisz tatę'
            },
            {
                target: '1.16',
                text: 'Szukasz sama'
            }
        ]
    },
    {
        id: '1.15',
        audio: '/audio/1.15.mp3',
        actions: [
            {
                target: '1.17',
                text: 'Szukasz dalej'
            }
        ]
    },
    {
        id: '1.16',
        audio: '/audio/1.16.mp3',
        actions: [
            {
                target: '1.17',
                text: 'Szukasz dalej'
            }
        ]
    },
    {
        id: '1.17',
        audio: '/audio/1.17.mp3',
        actions: [
            {
                target: '1.18',
                text: 'Idziesz po stasia'
            }
        ]
    },
    {
        id: '1.18',
        audio: '/audio/1.18.mp3',
        actions: [
            {
                target: '1.19-stas',
                text: 'Idziesz na skrzyżowanie'
            }
        ]
    },
    {
        id: '1.19-mania',
        audio: '/audio/1.19-mania.mp3',
        actions: [
            {
                target: '1.20',
                text: 'Czas na śledztwo!'
            }
        ]
    },
    {
        id: '1.19-stas',
        audio: '/audio/1.19-stas.mp3',
        actions: [
            {
                target: '1.20',
                text: 'Czas na śledztwo!'
            }
        ]
    },
    {
        id: '1.20',
        audio: '/audio/1.20.mp3',
        actions: [
            {
                target: '2.1',
                text: 'Woda - Rozdział 2'
            }
        ]
    }
];


const CHAPTER_2: GameNode[] = [
    {
        id: '2.1',
        audio: '/audio/2.1.mp3',
        actions: [
            {
                target: '2.3',
                text: 'Sprawdzacie inne działki'
            },
            {
                target: '2.18',
                text: 'Idziecie do zaworu głównego'
            },
            {
                target: '2.29',
                text: 'Czaicie się przy działających rurach'
            }
        ]
    },
    {
        id: '2.3',
        audio: '/audio/2.3.mp3',
        actions: [
            {
                target: '2.4',
                text: 'Pani Bożena'
            },
            {
                target: '2.5',
                text: 'Pan Tadek'
            }
        ]
    },
    {
        id: '2.4',
        audio: '/audio/2.4.mp3',
        actions: [
            {
                target: '2.7',
                text: 'Idziecie na działkę'
            }
        ]
    },
    {
        id: '2.5',
        audio: '/audio/2.5.mp3',
        actions: [
            {
                target: '2.14',
                text: 'Idziecie na działkę'
            }
        ]
    },
    {
        id: '2.6',
        audio: '/audio/2.6.mp3',
        actions: [
            {
                target: '2.19',
                text: 'Wchodzicie'
            },
            {
                target: '2.18',
                text: 'Zabawa w rybki - minigra'
            }
        ]
    },
    {
        id: '2.7',
        audio: '/audio/2.7.mp3',
        actions: [
            {
                target: '2.8',
                text: 'Wchodzicie'
            }
        ]
    },
    {
        id: '2.8',
        audio: '/audio/2.8.mp3',
        actions: [
            {
                target: '2.10',
                text: 'Pomagacie'
            },
            {
                target: '2.11',
                text: 'Wzywacie pomoc'
            }
        ]
    },
    {
        id: '2.9',
        audio: '/audio/2.9.mp3',
        actions: [
            {
                target: '2.8',
                text: 'Wchodzicie'
            }
        ]
    },
    {
        id: '2.10',
        audio: '/audio/2.10.mp3',
        actions: [
            {
                target: '2.12',
                text: 'Przeszukujecie chatkę'
            }
        ]
    },
    {
        id: '2.11',
        audio: '/audio/2.11.mp3',
        actions: [
            {
                target: '2.13',
                text: 'Obserwujecie'
            }
        ]
    },
    {
        id: '2.12',
        audio: '/audio/2.12.mp3',
        actions: [
            {
                target: '2.3',
                text: 'Sprawdzacie inne działki'
            },
            {
                target: '2.18',
                text: 'Idziecie do zaworu głównego'
            },
            {
                target: '2.29',
                text: 'Czaicie się przy działających rurach'
            }
        ]
    },
    {
        id: '2.13',
        audio: '/audio/2.12.mp3',
        actions: [
            {
                target: '2.3',
                text: 'Sprawdzacie inne działki'
            },
            {
                target: '2.18',
                text: 'Idziecie do zaworu głównego'
            },
            {
                target: '2.29',
                text: 'Czaicie się przy działających rurach'
            }
        ]
    },
    {
        id: '2.14',
        audio: '/audio/2.14.mp3',
        actions: [
            {
                target: '2.15',
                text: 'Kąpiecie się'
            },
            {
                target: '2.16',
                text: 'Ostrzegacie Pana Tadka'
            }
        ]
    },
    {
        id: '2.15',
        audio: '/audio/2.15.mp3',
        actions: [
            {
                target: '2.17',
                text: 'Przysłuchujecie się'
            }
        ]
    },
    {
        id: '2.16',
        audio: '/audio/2.16.mp3',
        actions: [
            {
                target: '2.17',
                text: 'Przysłuchujecie się'
            }
        ]
    },
    {
        id: '2.17',
        audio: '/audio/2.17.mp3',
        actions: [
            {
                target: '2.3',
                text: 'Sprawdzacie inne działki'
            },
            {
                target: '2.18',
                text: 'Idziecie do zaworu głównego'
            },
            {
                target: '2.29',
                text: 'Czaicie się przy działających rurach'
            }
        ]
    },
    {
        id: '2.18',
        audio: '/audio/2.18.mp3',
        actions: [
            {
                target: '2.24',
                text: 'Chicho'
            },
            {
                target: '2.211',
                text: 'Głośno'
            }
        ]
    },
    {
        id: '2.19',
        audio: '/audio/2.19.mp3',
        actions: [
            {
                target: '2.20',
                text: 'Płyniecie do zaworu'
            },
            {
                target: '2.21',
                text: 'Pomagacie zejść Strzelczykowi juniorowi'
            }

        ]
    },
    {
        id: '2.20',
        audio: '/audio/2.20.mp3',
        actions: [
            {
                target: '2.22',
                text: 'Wyplątujecie Strzelczyka'
            }
        ]
    },
    {
        id: '2.21',
        audio: '/audio/2.21.mp3',
        actions: [
            {
                target: '2.22',
                text: 'Wyplątujecie Strzelczyka'
            }
        ]
    },
    {
        id: '2.22',
        audio: '/audio/2.22.mp3',
        actions: [
            {
                target: '2.3',
                text: 'Sprawdzacie inne działki'
            },
            {
                target: '2.18',
                text: 'Idziecie do zaworu głównego'
            },
            {
                target: '2.29',
                text: 'Czaicie się przy działających rurach'
            }
        ]
    },
    {
        id: '2.23',
        audio: '/audio/2.23.mp3',
        actions: [
            {
                target: '2.25',
                text: 'W stronę odgłosów'
            }
        ]
    },
    {
        id: '2.24',
        audio: '/audio/2.24.mp3',
        actions: [
            {
                target: '2.25',
                text: 'W stronę odgłosów'
            }
        ]
    },
    {
        id: '2.211',
        audio: '/audio/2.211.mp3',
        actions: [
            {
                target: '2.221',
                text: 'Idziecie dalej'
            }
        ]
    },
    {
        id: '2.221',
        audio: '/audio/2.211.mp3',
        actions: [
            {
                target: '2.23',
                text: 'Wchodzicie'
            },
            {
                target: '2.25',
                text: 'W stronę odgłosów'
            }
        ]
    },
    {
        id: '2.25',
        audio: '/audio/2.25.mp3',
        actions: [
            {
                target: '2.40',
                text: 'Pomagacie'
            },
            {
                target: '2.41',
                text: 'Nie pomagacie'
            }
        ]
    },
    {
        id: '2.26.0',
        audio: '/audio/2.26.mp3',
        actions: [
            {
                target: '2.27.1',
                text: 'Kto mógł to zrobić?'
            },
            {
                target: '2.28.1',
                text: 'Kiedy to się mogło stać?'
            }
        ]
    },
    {
        id: '2.27.0',
        audio: '/audio/2.27.mp3',
        actions: [
            {
                target: '2.26.1',
                text: 'Jak została uszkodzona rura'
            },
            {
                target: '2.28.1',
                text: 'Kiedy to się mogło stać?'
            }
        ]
    },
    {
        id: '2.28.0',
        audio: '/audio/2.28.mp3',
        actions: [
            {
                target: '2.26.1',
                text: 'Jak została uszkodzona rura'
            },
            {
                target: '2.27.1',
                text: 'Kto mógł to zrobić?'
            }
        ]
    },
    {
        id: '2.26.1',
        audio: '/audio/2.26.mp3',
        actions: [
            {
                target: '2.391',
                text: 'Pytacie dalej'
            }
        ]
    },
    {
        id: '2.27.1',
        audio: '/audio/2.27.mp3',
        actions: [
            {
                target: '2.391',
                text: 'Pytacie dalej'
            }
        ]
    },
    {
        id: '2.28.1',
        audio: '/audio/2.28.mp3',
        actions: [
            {
                target: '2.391',
                text: 'Pytacie dalej'
            }
        ]
    },

    {
        id: '2.29',
        audio: '/audio/2.29.mp3',
        actions: [
            {
                target: '2.30',
                text: 'Blisko miejsca awarii'
            }
        ]
    },
    {
        id: '2.30',
        audio: '/audio/2.30.mp3',
        actions: [
            {
                target: '2.32',
                text: 'Jadzia'
            }
        ]
    },
    {
        id: '2.31',
        audio: '/audio/2.31.mp3',
        actions: [
            {
                target: '2.36',
                text: 'Wchodzicie'
            }
        ]
    },
    {
        id: '2.32',
        audio: '/audio/2.32.mp3',
        actions: [
            {
                target: '2.33',
                text: 'Pomagacie łapać koty'
            }
        ]
    },
    {
        id: '2.33',
        audio: '/audio/2.33.mp3',
        actions: [
            {
                target: '2.35',
                text: 'Oddajecie koty'
            }
        ]
    },
    {
        id: '2.34',
        audio: '/audio/2.34.mp3',
        actions: [
            {
                target: '2.3',
                text: 'Sprawdzacie inne działki'
            },
            {
                target: '2.18',
                text: 'Idziecie do zaworu głównego'
            },
            {
                target: '2.29',
                text: 'Czaicie się przy działających rurach'
            }
        ]
    },
    {
        id: '2.35',
        audio: '/audio/2.35.mp3',
        actions: [
            {
                target: '2.3',
                text: 'Sprawdzacie inne działki'
            },
            {
                target: '2.18',
                text: 'Idziecie do zaworu głównego'
            },
            {
                target: '2.29',
                text: 'Czaicie się przy działających rurach'
            }
        ]
    },
    {
        id: '2.36',
        audio: '/audio/2.36.mp3',
        actions: [
            {
                target: '2.37',
                text: 'Podchodzicie'
            },
        ]
    },
    {
        id: '2.37',
        audio: '/audio/2.37.mp3',
        actions: [
            {
                target: '2.3',
                text: 'Sprawdzacie inne działki'
            },
            {
                target: '2.18',
                text: 'Idziecie do zaworu głównego'
            },
            {
                target: '2.29',
                text: 'Czaicie się przy działających rurach'
            }
        ]
    },
    {
        id: '2.39',
        audio: '/audio/2.39.mp3',
        actions: [
            {
                target: '2.3',
                text: 'Sprawdzacie inne działki'
            },
            {
                target: '2.18',
                text: 'Idziecie do zaworu głównego'
            },
            {
                target: '2.29',
                text: 'Czaicie się przy działających rurach'
            }
        ]
    },


    {
        id: '2.391',
        audio: '/audio/2.391.mp3',
        actions: [
            {
                target: '2.3',
                text: 'Sprawdzacie inne działki'
            },
            {
                target: '2.18',
                text: 'Idziecie do zaworu głównego'
            },
            {
                target: '2.29',
                text: 'Czaicie się przy działających rurach'
            }
        ]
    },

    {
        id: '2.40',
        audio: '/audio/2.40.mp3',
        actions: [
            {
                target: '2.26.0',
                text: 'Jak została uszkodzona rura'
            },
            {
                target: '2.27.0',
                text: 'Kto mógł to zrobić?'
            },
            {
                target: '2.28.0',
                text: 'Kiedy to się mogło stać?'
            }
        ]
    },
    {
        id: '2.41',
        audio: '/audio/2.41.mp3',
        actions: [
            {
                target: '2.3',
                text: 'Sprawdzacie inne działki'
            },
            {
                target: '2.18',
                text: 'Idziecie do zaworu głównego'
            },
            {
                target: '2.29',
                text: 'Czaicie się przy działających rurach'
            }
        ]
    },


]


export const gameTree: GameNode[] = [
    ...CHAPTER_1,
    ...CHAPTER_2
]
