import { useLocation } from "react-router";
import Sticky from "react-sticky-el";
import styled from "styled-components"
import { gameTree } from "../../modules/main/game-tree";

const Container = styled.div`

    max-width: 1200px;
    margin: 0 auto;

    display: flex;

    .index-container {
        flex: 1 1 20%;

    }


    .index {
        display: flex;
        flex-direction: column;
        padding: 0 1em;

        max-height: 100vh;
        overflow-y: scroll;
    }

    .nodes {
        flex: 1 1 100%;
    }

    .node {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;

        border: 1px solid black;
        margin: 1em 0;
    }

    .id {
        background: #d9d9d9;
        padding: .5em;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .audio {
        background: #d9d9d9;
        padding: .5em;
        display: flex;
        justify-content:center;
        align-items: center;
        flex-direction: column;
    }

    .actions {
        background: #d9d9d9;
        padding: .5em;
        display: flex;
        justify-content:center;
        align-items: center;
    }

    .actions {
        grid-row: 2;
        grid-column: span 2;
        padding: .5em;
    }

    .sources {
        padding: .5em;
        background: #d9d9d9;
        grid-column: span 2;
    }
`;

const NodesPage = () => {

    const tree = gameTree;

    const {hash} = useLocation();

    return <Container>
        <div className="index-container">
            <Sticky>
                <div className="index">
                    {
                        tree.map(node => {
                            return <a key={node.id} href={`#${node.id}`}><strong>{node.id}</strong></a>
                        })
                    }
                </div>
            </Sticky>
        </div>
        <div className="nodes">
            {
                tree.map((node) => {
                    const sources = tree.filter((n) => {
                        return n.actions.some(a => a.target === node.id);
                    })

                    return <div className='node' key={node.id} id={node.id} style={{boxShadow: '#' + node.id === hash ? '0 0 4px 4px pink' : 'none', background: '#' + node.id === hash ? 'pink' : 'none'}}>
                        <div className='id'>ID: <strong>{node.id}</strong></div>
                        <div className='audio'>
                            <div>Audiofile: <strong>{node.audio}</strong></div>
                            <div><audio src={node.audio} controls={true} /></div>
                        </div>
                        <div className='actions'>
                            <div>
                                {
                                    node.actions.map((action) => {
                                        return <div>
                                            <a href={`#${action.target}`}><strong>{action.target}</strong></a> {action.text}
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                        <div className="sources">Do tego widoku można dojść z widoków:
                            {
                                sources.map((s) => {
                                    return <a key={s.id} href={`#${s.id}`}><strong>{s.id}</strong></a>
                                    //@ts-ignore
                                }).reduce((prev, curr) => [prev, ', ', curr], [])
                            }
                        </div>
                    </div>
                })
            }
        </div>
    </Container>
}

export default NodesPage;
